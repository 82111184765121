.pumpkinBody {
    background-color: var(--pumpkin-color);
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    position: relative;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    overflow: hidden;
    flex: 0 0 auto;
    transition: all .3s;
}

.pumpkinContainer {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex: 0 0 auto;
    position: relative;
    height: 2rem;
    width: 2rem;
}


.pumpkinLeftEye, .pumpkinRightEye, .pumpkinNose {
    top: 6px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 8px solid black;
}

.pumpkinLeftEye {
    left: 5px;
    z-index: 2;
}

.pumpkinRightEye {
    right: 5px;
    z-index: 2;
}

.pumpkinNose {
    left: 12px;
    top: 12px;
    z-index: 2;
}

.pumpkinMouth {
    position: absolute;
    background: white;
    background: black;
    height: 11px;
    width: 26px;
    top: 19px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    z-index: 0;
}

.pumpkinMouth::after {
    position: absolute;
    content: '';
    height: 22px;
    width: 47px;
    top: -20px;
    left: -3px;
    left: -12px;
    /* background: #ae04ff; */
    background: var(--pumpkin-color);
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
}

.pumpkinTopLeftTooth, .pumpkinTopRightTooth, .pumpkinBottomTooth {
    position: absolute;
    background: var(--pumpkin-color);
    height: 5px;
    width: 5px;
}

.pumpkinTopLeftTooth, .pumpkinTopRightTooth {
    top: 20px;
}

.pumpkinTopLeftTooth {
    left: 9px;
}

.pumpkinTopRightTooth {
    right: 9px;
}

.pumpkinBottomTooth {
    top: 28px;
}