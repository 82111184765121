.spookyContainer {
    background: black;
    color: white;
    font-weight: 600;
    padding: 1rem;
}

.mainPumpkinImage {
    max-width: 100%;
}

.competitionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
    max-width: 100%;
    overflow: hidden;
}

.competitionTitle {
    text-align: center;
}

.competitionContainer div {
    /* flex: 1 1 40%; */
}

.competitionContainer img {
    /* flex: 1 1 40%; */
    /* max-width: 40%; */
    width: 500px;
}

h1, h2, h3, h4, h5 {
}

.halloweenTitle {
    color: #ff7002;
    font-weight: bolder;
    font-size: 1.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    padding-left: 0px;
    padding-right: 0px;
}

.bonesContainer {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    padding: 1rem;
    overflow: hidden;
}

.infoBullet {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.infoIndent {
    /* padding: 1rem; */
    padding-left: 2rem;
    font-size: .9rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.halloweenIcon {
    /* color: #fd9701; */
    color: #ae04ff;
    font-size: 2rem !important;
}