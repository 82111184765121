.exampleTileLabelContainer {
    display: flex;
    gap: var(--defaultPadding);
    align-items: center;
}

.shapeOptionsContainer {
    display: flex;
    justify-content: space-around;
}

.shapeChangeButtonContainer {
    display: flex;
    align-items: center;
    gap: var(--defaultPadding);
    flex-wrap: wrap;
}

.exampleTileLabelContainer span {
    font-weight: bold;
}

.seasonActionsContainer {
    display: grid;
    grid-template-columns: auto auto;
}

.seasonActionsContainer div {
    padding: calc(.5 * var(--defaultPadding));
    align-items: center;
    display: flex;
    gap: var(--defaultPadding);
    border: solid 1px rgb(207, 207, 207);
}

.seasonActionsContainerHeading {
    font-weight: bold;
    border-bottom: solid 2px black !important;
}

.cartographerContainer{
    width: 100%;
    padding: 2rem 0;
    margin: 0;
    position: relative;
    background: var(--clrBackground);
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    gap: var(--defaultPadding);
}

.cartographerContainer.overlayed{
    max-height: 100vh;
    max-width: 100vw;
}


.cartographerGrid, .exampleTileGrid{
    height: calc(7.3 * var(--tileWidth));
    width: calc(7.3 * var(--tileWidth)); 
    display: grid; 
    grid-template-columns: auto auto auto auto auto auto auto; 
    grid-template-rows: auto auto auto auto auto auto auto; 
    grid-column-gap: 0;
    grid-row-gap: 0; 
}

.cartographerGrid{
    height: calc(9.3 * var(--tileWidth));
    margin-left: var(--defaultMargin); 
    width: calc(9.3 * var(--tileWidth));
    grid-template-columns: auto auto auto auto auto auto auto auto auto;
}

.cartographerGrid.selectingShape .blankTile:hover{
    cursor: pointer; 
}


.exampleTileGrid{
    height: calc(7.3 * .3 * var(--tileWidth)); 
    width: calc(7.3 * .3 * var(--tileWidth)); 
    position: relative; 
}

.blankTile, .blankExampleTile{
    border-style:solid;
    border-color: black; 
    border-width: 2px; 
    margin: 0px; 
    height: var(--tileWidth);
    width: var(--tileWidth); 
    background-color: white; 
    transition: all .3s ease-in-out; 
    position: relative; 
}

.blankTile.hover{
    border-color: blue; 
    transform: scale(1.05); 
}

.blankTile.hoverBad{
    border-color: red; 
}

.neutralTile{
    background-color: grey; 
}

.blankExampleTile{
    height: var(--exampleTileWidth); 
    width: var(--exampleTileWidth); 
}

.contractorTile{
    background-color: black; 
}

.dataAnalyticsTile{
    background-color: #1E88E5; 
}

.ccServerTile{
    background-color: #FFC107; 
}

.ioSystemTile{ 
    background-color: rgb(0, 158, 115); 
}

.powerSupply{
    background-color: #D81B60; 
}

.featureTile{
    position: relative; 
}

.featureTile::after{
    position: absolute; 
    content: ''; 
    width: 50%; 
    height: 50%; 
    border-color: coral; 
    border-width: 2px; 
    border-radius: 50%; 
    border-style: solid; 
    top: 25%;
    left: 25%; 
}

.defect{
    background-color: #785EF0; 
}

.dataContainer{
    display: flex;
    flex-wrap: wrap;
    gap: var(--defaultPadding);
}

.startButton{
    border-style: solid; 
    border-width: 2px; 
    border-color: var(--clrPrimary); 
    color: var(--clrPrimary); 
    padding: var(--defaultPadding) 0; 
    width: 35%; 
    text-align: center; 
    font-weight: bold; 
    font-size: var(--largeFontSize); 
    background-color: white; 
    transition: all .3s ease-in-out; 
    cursor: pointer; 
}

.startButton:hover{
    background-color: var(--clrPrimary);
    color: white; 
}

.contractsContainer{
    display: flex;
    flex-wrap: wrap;
    gap: var(--defaultPadding);
}

.contractsContainerTitle{
    font-size: var(--largeFontSize); 
    color: var(--clrPrimary); 
    font-weight: bold; 
}

.contractBox{
    border-style: solid;
    border-width: 0px; 
    border-top-width: 3px; 
    border-color: var(--clrSecondary); 
    padding: var(--defaultPadding) 0; 
    background-color: white; 
    position: relative; 

    display: flex;
    flex: 1 1 auto;
    padding: var(--defaultPadding);
    position: relative;
    background-color: rgb(245,248,250);
    border: 1px solid var(--rh__neutral-bg3);
    border-radius: var(--rh__size-palette__borderRadius);
    box-sizing: border-box;
}

.contractBoxName{
    font-weight: bold; 
    font-size: var(--largeFontSize); 
    display: block;
    width: 100%; 
    text-align: left; 
    margin-left: var(--defaultPadding); 
}

.contractBoxDescription{
    padding: var(--defaultPadding); 
    user-select: none;
    text-align: left; 
}

.cartographerShowArrowContainer{
    padding: 0 var(--defaultPadding);
    cursor: pointer; 
}

.cartographerShowArrowContainer .showMoreText{
    vertical-align: middle; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    text-align: center; 
}

.cartographerShowArrowContainer .showArrowContainer,
.cartographerShowArrowContainer .showArrow{
    border-color: var(--clrPrimary); 
}

.cartographerShowArrowContainer:hover >.showMoreText{
    font-size: calc( 1.1 * var(--defaultFontSize));
}

.cartographerShowArrowContainer:hover >.showArrowContainer{
    background-color: var(--clrPrimary); 
}

.cartographerShowArrowContainer:hover > .showArrowContainer .showArrow{
    border-color: white; 
}

.contractExample.hidden, *.hidden{
    max-height: 0rem; 
    margin: 0px; 
    transition: all .3s ease-in-out; 
    overflow: hidden; 
}

.contractContainer {
    display: flex;
    flex-direction: column;
    gap: var(--defaultPadding);
}

.shapeBox {
    display: flex;
    flex: 1 1 auto;
    padding: var(--defaultPadding);
    position: relative;
    background-color: rgb(245,248,250);
    border: 1px solid var(--rh__neutral-bg3);
    border-radius: var(--rh__size-palette__borderRadius);
    box-sizing: border-box;
}

.sprintTile.orange{
    border-color: var(--clrPrimary); 
    font-weight: bold; 
}

.textureContainer{
    display: flex;
    margin: 0 auto;
    margin-bottom: 4rem; 
}

.textureContainer .shapeRotateContainer.small{
    position: absolute;
    right: 2rem;
    top: 5.5rem;
}

.shapeContainer{
    display: grid; 
    grid-template-rows: auto auto auto auto; 
    grid-template-columns: var(--tileWidth) var(--tileWidth) var(--tileWidth) var(--tileWidth); 
}

.shapeContainer.small{
    grid-template-columns: var(--exampleTileWidth) var(--exampleTileWidth) var(--exampleTileWidth) var(--exampleTileWidth); 
}

.shapeContainer.small.spaced{
    justify-content: space-between;
     display: inline-block; 
    width: calc(6 * (2rem + var(--exampleTileWidth)));
}

.tileTextureContainer{
    display: inline-block;
    cursor: pointer;
    transition: all .3s ease-in-out;
    padding: 0 1rem; 
}

.tileTextureContainer:hover > .blankExampleTile{
    transform: scale(1.2); 
}

.shapeContainer.small.spaced .blankExampleTile:hover{
    transform: scale(1.1); 
}

*.ninetyDeg{
    transform:rotate(90deg);
}

.rotateButtonPositioner, .flipButtonPositioner{
    display: inline-block; 
    position: relative;
    background-color: white; 
    cursor: pointer; 
}

.rotateButtonContainer{
    height: 4rem;
    width: 4rem;
    /* position: absolute; */
    /* border-color: var(--clrSecondary); */
    border-style: solid;
    overflow: hidden;
    border-radius: 2.5rem;
    padding: 5px;
    border-width: 2px;
    left: -1.5rem;
    top: -1.2rem;
    transition: all .3s ease-in-out;
}

.flipButtonPositioner .rotateButtonContainer{
    height: 4rem;
    width: 4rem;
    /* position: absolute; */
    border-color: var(--clrSecondary);
    border-style: solid;
    overflow: hidden;
    border-radius: 2.5rem;
    padding: 5px; 
    border-width: 2px; 
    left: 4.5rem;
    top: -1.2rem;
    transition: all .3s ease-in-out; 
}

.definitionHeader {
    display: flex;
    align-items: center;
    gap: var(--defaultPadding);
}

*.ninetyDeg {
    transform:rotate(90deg);
}

*.eightyDeg {
    transform:rotate(180deg);
}

*.seventyDeg {
    transform: rotate(270deg);
}

.rotateCircleInner{
    position: relative;
    height: 100%;
    width: 100%;
    transition: all .3s ease-in-out; 
}

.rotateDegrees{
    position: absolute;
    font-size: calc(var(--defaultFontSize) * 1);
    top: 40%; 
    left: 30%; 
    z-index: 3; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    font-weight: bold; 
}

.rotateDegrees::after{
    content: "";
    border-style: solid;
    border-radius: 25px;
    border-width: 2px;
    border-color: black;
    position: absolute;
    height: 0.3ch;
    width: 0.3ch;
    top: -0.25ch;
}

.rotateCircle{
    padding: 10%;
    height: calc(100% - 8px);
    width: calc(100% - 8px);
    height: 45%;
    width: 45%;
    top: 10%;
    left: 6%;
    border-style: solid;
    border-color: var(--clrSecondary);
    border-width: 4px;
    border-radius: 50%;
    position: relative;
}

.rotateButtonContainer:hover{
    border-color: var(--clrPrimary); 
}

.rotateButtonContainer:hover > .rotateCircleInner .rotateArrow,
.rotateButtonContainer:hover > .rotateCircleInner .rotateCircle{
    border-color: var(--clrPrimary); 
}

.rotateCircle::before, .rotateCircle::after{
    content: ''; 
    display: block; 
    position: absolute; 
    height: 150%; 
    width: 150%; 
    z-index: 2; 
    background-color:white; 
    transition: all .3s ease-in-out; 
}

.rotateCircle::before{
    top: 50%; 
    left: -10%; 
}

.rotateCircle::after{
    top: -10%; 
    left: 50%; 
}

.rotateArrow{
    z-index: 3;
    position: absolute;
    top: -20%;
    left: 28%;
    border-style: solid;
    border-width: 4px 4px 0px 0px;
    border-color: var(--clrSecondary);
    transform: rotate(45deg);
    height: 20%;
    width: 20%;
    transition: all .3s ease-in-out;
}

.shapeRotateContainer{
    width: calc(4 * var(--tileWidth)); 
}

.shapeRotateContainer.small{
    display: flex;
    transition: all .3s ease-in-out; 
}

.shapeRotateContainer.option.small{
    cursor: pointer; 
}

.shapeRotateContainer.small::after,
.textureContainer .shapeContainer.small.spaced .blankExampleTile::after{
    top: 40%; 
    left: 0%; 
    height: calc(2 * var(--exampleTileWidth)); 
    width: calc(2 * var(--exampleTileWidth)); 
    border-radius: 50%; 
    background-color: var(--clrPrimary); 
    z-index: 1; 
    transition: all .3s ease-in-out; 
}

.shapeRotateContainer.small::before,
.textureContainer .shapeContainer.small.spaced .blankExampleTile::before {
    top: 44%;
    left: 14%;
    height: calc(1 * var(--exampleTileWidth));
    width: calc(.5 * var(--exampleTileWidth));
    transform: rotate(45deg);
    border-color: white;
    border-style: solid;
    z-index: 2;
    transition: all .3s ease-in-out; 
}

.textureContainer .shapeContainer.small.spaced .blankExampleTile::before{
    top: 65%;
    left: 55%; 
}

.shapeRotateContainer.small::before, 
.shapeRotateContainer.small::after,
.textureContainer .shapeContainer.small.spaced .blankExampleTile::before,
.textureContainer .shapeContainer.small.spaced .blankExampleTile::after{
    content: ""; 
    position: absolute; 
    max-height: 0px;
    max-width: 0px; 
    border-width: 0; 
}

.shapeRotateContainer.option.small:hover{
    transform: scale(1.07); 
}

.shapeRotateContainer.small .rotateCircleInner .shapeContainer [class = "blankExampleTile"]{
    border-width: 0px; 
    z-index: -1; 
    margin: 0px; 
}

.shapeRotateContainer.small .rotateCircleInner .shapeContainer .blankExampleTile{
    border-color:black; 
    margin: -1px; 
}

.shapeRotateContainer.option.small:hover::after, 
.shapeRotateContainer:hover::before,
.textureContainer .shapeContainer.small.spaced .blankExampleTile:hover::after,
.textureContainer .shapeContainer.small.spaced .blankExampleTile:hover::before{
    max-height: calc(2 * var(--exampleTileWidth)); 
    max-width: calc(2 * var(--exampleTileWidth)); 
}

.shapeRotateContainer.option.small:hover::before,
.textureContainer .shapeContainer.small.spaced .blankExampleTile:hover::before
{
    border-width: 0 calc(.3 * var(--exampleTileWidth)) calc(.3 * var(--exampleTileWidth)) 0;
}

.shapeRotateContainer .rotateCircleInner{
    transition: all 0s; 
}

.showMoreText{
    padding-right: var(--default-padding); 
    cursor:pointer; 
    font-size: calc(var(--default-text-size)*1.2);
    font-weight: 600; 
    transition: all .3s ease-in-out; 
}

.showArrowContainer{
    position:relative;
    height: 2rem;
    width: 2rem; 
    border-style: solid; 
    border-width: 2px; 
    border-color: var(--clr-fourth); 
    border-radius: 1rem; 
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    vertical-align: middle;
}

.showArrow{
    position: absolute;
    left: 0.55rem;
    top: 0.45rem;
    height: 0.7rem;
    width: 0.7rem;
    border-style: solid;
    border-top-width: 0px;
    border-left-width: 0px;
    transform: rotate(45deg);
    border-color: var(--clr-fourth); 
    transition: all .3s; 
}

.showArrow.toggled{
    transform: rotate(-135deg); 
}

.shapeOptions{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--defaultPadding);
}

.shapeBox .contractBoxName{
    font-size: calc(.9 * var(--largeFontSize)); 
}

.cartographerToolbarIcon {
    transition: all .3s;
    cursor: pointer;
    color: var(--clrPrimary);
}

.cartographerToolbarIcon:hover {
    transform: scale(1.5);
}

.shapeButton{
    padding: var(--defaultPadding); 
    cursor: pointer; 
    display: inline-block; 
    margin: var(--defaultPadding); 
    border-style: solid;
    border-color: var(--clrSecondary); 
    border-width: 1px; 
    color: var(--clrSecondary); 
    background-color: white; 
    transition: all .2s ease-in-out; 
}

.shapeButton:hover{
    color: white; 
    background-color: var(--clrSecondary); 
}

.pointsContainer{
    font-weight: bold; 
    border-color: green; 
    border-style: solid;
    border-width: 2px; 
    border-radius: 55%;  
    padding: calc(.5*var(--defaultPadding)); 
    font-size: var(--smallFontSize);
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

*.inline{
    display: inline-block; 
}

.instructionsBackground{
    top: 0px; 
    left: 0px; 
    padding: var(--defaultMargin); 
    position: absolute; 
    height: 100vh; 
    width: calc(100vw - 2*var(--defaultMargin)); 
    z-index: 10; 
    background-color: rgba(0,0,0,.5411764705882353);  
}

.instructionsContainer{
    background-color: white;
    padding: var(--defaultMargin); 
}

.instructionBullet{
    text-align: left; 
    margin: var(--defaultPadding) 0; 
}

.fancyWord{
    font-weight: bold; 
    display: inline; 
    cursor: pointer; 
    position: relative; 
    padding: 0 1ch; 
}

.definition{
    max-height: 0px;
    font-size: var(--smallFontSize);
    width: 0px; 
    position: absolute; 
    overflow: hidden; 
    top: 0; 
    right: 0; 
    transition: all .3s ease-in-out; 
    background-color: white; 
    padding: 0; 


}

.definitionTitle{
    margin-bottom: var(--defaultPadding); 
    position: relative; 
    border: solid 1px black; 
    padding: var(--defaultPadding); 
    font-weight: bolder; 
    background-color: rgb(245,248,250);
    border: 1px solid var(--rh__neutral-bg3);
    border-radius: var(--rh__size-palette__borderRadius);
    box-sizing: border-box;
}


.fancyWord:hover > .definition{
    max-height: 6rem; 
    width: 40ch; 
    padding: var(--defaultPadding); 
    z-index: 5; 
    background-color: var(--rh__neutral-bg1);
    border: 1px solid var(--rh__neutral-bg3);
    border-radius: var(--rh__size-palette__borderRadius);
    box-sizing: border-box;
    border-top-color: var(--clrPrimary); 
    border-top-width: 2px; 
}

.definitionTitle .definition{
    width: auto;
    height: auto;
    max-height: fit-content;
    font-size: var(--defaultFontSize);
    position: relative;
    margin-top: var(--defaultPadding);
    background-color: inherit;
    display: flex;
    flex-direction: column;
    gap: var(--defaultPadding);
}

.manipulateShapeButton {
    flex: 1 1 auto;
    gap: var(--defaultPadding);
}

.rotateButton {
    transition: all .3s !important;
}

.instructionGrid{
    display: grid; 
    grid-template-columns: 33% 33% 33%; 
    grid-column-gap: var(--defaultPadding); 
}

.instructionGrid .instructionBullet{
    padding: var(--defaultPadding); 

    background-color: var(--rh__neutral-bg1);
    border: 1px solid var(--rh__neutral-bg3);
    border-radius: var(--rh__size-palette__borderRadius);
    box-sizing: border-box;
    border-top-color: var(--clrPrimary);
    border-top-width: 2px;
}

.instructionGridTitle{
    font-size: calc(var(--defaultFontSize)*1.1);
    font-weight: bold;
    display: flex;
    gap: var(--defaultPadding);
    align-items: center;
    margin: var(--defaultPadding) 0;
}

.shapeBox .MuiButton-root {
    font-size: .7rem;
}

.spanThree{
    grid-column: 1 / 4; 
}

.spanTwo{
    grid-column: 1 / 3;
}

.startBtnBox .startButton{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 9%;
    padding: var(--defaultPadding) 5rem;
    text-align: center;
}

.contractBoxSprintContainer{
    padding: calc(.3*var(--defaultPadding)) calc(.5* var(--defaultPadding)); 
    border-style: solid;
    border-color: var(--clrSecondary); 
    margin-left: calc(.2*var(--defaultPadding)); 
    display: inline-block; 
    border-radius: 50%; 
}

.smallText{
    font-size: var(--smallFontSize); 
}

.contractsShowMore{
    position: absolute; 
    top: -1.5rem; 
    right: 2rem; 
}

.contractsShowMore{
    z-index: 4;
}

.sprintTile{
    display: inline-block; 
    margin: 0 calc(.5*var(--defaultPadding)); 
    border-style: solid; 
    border-color: var(--clrSecondary); 
}

.storyTile{
    display:inline-block;
    height: 1rem;
    width: 1rem;
    border-style: solid;
    border-color: black;
    border-width: 1px; 
}

.storyTile.grey{
    background-color: var(--clrSecondary); 
}

.contractBox.status{
    text-align: left; 
    padding: var(--defaultPadding); 
}

.dataForm{
    margin: var(--defaultPadding) 0; 
}

.timeBox{
    border-style: solid;
    padding: calc(2 * var(--defaultPadding)) var(--defaultPadding); 
    border-color: var(--clrSecondary); 
    border-width: 2px; 
    font-size: calc(1.3 * var(--largeFontSize)); 
    text-align: center; 
}


.before{
    left: calc(-2*var(--defaultPadding)); 
    top: 50%; 
    position: absolute; 
    width: 1rem;
    height: 1rem;
    font-weight: bolder; 
    font-size: var(--largeFontSize);
}

.above{
    top: calc(-2* var(--defaultPadding)); 
    left: 50%; 
    position: absolute; 
    width: 1rem;
    height: 1rem;
    font-weight: bolder; 
    font-size: var(--largeFontSize);
}

.instructionsContainer .contractBox{
    background-color: var(--rh__neutral-bg1);
    border: 1px solid var(--rh__neutral-bg3);
    border-radius: var(--rh__size-palette__borderRadius);
    box-sizing: border-box;
    border-top-width: 3px;
    border-top-color: var(--clrSecondary);
}

.instructionsContainer .contractBoxName{
    margin-left: 0; 
}

.contractBox .instructionGridTitle{
    margin-top: 0; 
    margin-left: var(--defaultPadding); 
}

.valueBox{
    width: 42%; 
}

.valueLabel::after{
    content: '';
    position: absolute; 
    height: 100%; 
    width: 2px; 
    background-color: var(--clrSecondary); 
    left: calc(-1 * var(--defaultPadding)); 
}

.valueLabel{
    float: right;
    font-weight: bolder; 
    position: relative; 
}

.shapeButtonGrid{
    display: flex;
    gap: var(--defaultPadding);
    justify-content: space-evenly;
}

.instructionReel{
    position: relative; 
    padding: var(--defaultPadding); 
    background-color: var(--rh__neutral-bg1);
    border: 1px solid var(--rh__neutral-bg3);
    border-radius: var(--rh__size-palette__borderRadius);
    box-sizing: border-box;
    height: 28rem; 
    margin-bottom: var(--defaultMargin); 
}

.prevArrow, .nextArrow{
    position: absolute; 
    height: 1.5rem;
    width: 1.5rem; 
    border-color: var(--clrSecondary); 
    border-style: solid;
    border-width: 4px; 
    border-top-width: 0px;
    border-left-width: 0px; 
    top: 50%; 
    z-index: 12; 
    transition: all .3s ease-in-out; 
    cursor: pointer; 
}

.prevArrow:hover, .nextArrow:hover{
    border-color: var(--clrPrimary); 
}

.prevArrow{
    left: -1rem; 
    transform: rotate(135deg); 
}

.nextArrow{
    right: -1rem; 
    transform: rotate(-45deg); 
}

.bulletContainer{
    position: absolute; 
    bottom: calc(.5 * var(--defaultPadding)); 
    left: 50%;
    transform: translate(-50%);
}

.bullet{
    height: 1rem; 
    width: 1rem; 
    margin: 0 .5rem; 
    background-color: var(--clrSecondary); 
    transition: all .3s ease-in-out; 
    border-radius: 50%; 
    display: inline-block; 
    cursor: pointer; 
}

.bullet.selected{
    background-color: var(--clrPrimary);
}

.bullet:hover{
    transform: scale(1.2); 
}

.flipCircle{
    height: 100%;
    width: 100%;
    top: 0%;
    left: 0%;
    border-radius: 50%;
    position: relative;
}

.arrow{
    position: absolute; 
    height: 12%; 
    width: 60%; 
    background-color: var(--clrSecondary); 
    left: 20%; 
}

.flipArrow .arrow:nth-child(1){
    top: 34%; 
    transform:rotate(-45deg); 
}

.flipArrow .arrow:nth-child(2){
    top: 62%; 
    transform:rotate(135deg); 
}

.flipArrow .arrow::after{
    content: '';
    z-index: 3;
    position: absolute;
    border-style: solid;
    border-width: 6px 6px 0px 0px;
    border-color: var(--clrSecondary);
    transform: rotate(45deg);
    height: 143%;
    width: 20%;
    height: 0.25rem;
    left: 49%;
    width: 1rem;
    top: -83%;
    height: 1rem;
    transition: all .3s ease-in-out;
}

*.flip{
    transform: rotateY(180deg);
}

.exampleGridPoints{
    position: absolute;
    top:50%;
    right: calc(-1.5*var(--defaultMargin));
}

@media(max-width: 2000px){
    .shapeBox .contractBoxName {
        font-size: calc(.85 * var(--largeFontSize));
    }

    .shapeRotateContainer.small {
        margin: 0 calc(1 * var(--defaultMargin));
    }
}

@media(max-width: 1700px){

    .dataContainer{
        margin: 0 calc(.5*var(--defaultMargin));
    }
    .contractsContainer{
        grid-template-columns: 100%; 
    }

    .dataContainer .startButton{
        width:100%; 
    }

    .shapeBox .contractsContainerTitle {
        font-size: calc(.8*var(--largeFontSize));
        text-align: center; 
    }

}

@media(max-width: 1400px){
    /* :root{
        --defaultFontSize: 1.2rem; 
        --tileWidth: 3.5rem; 
        --defaultPadding: .75rem;
    } */

    .contractsContainer{
        grid-template-columns: 100%;
    }

    .instructionsContainer .contractsContainer{
        grid-template-columns: 50% 50%
    }

    .rotateButtonContainer{
        left: -3.5rem;
    }

    .flipButtonPositioner .rotateButtonContainer{
        left: 1.5rem; 
    }

    .timeBox{
        padding: 0; 
    }
}

@media(max-width: 768px) {

    :root {
        --exampleTileWidth: 1.5rem;
    }

    .definitionTitle .definition {
        font-size: 1em;
    }

    .seasonActionsContainer div {
        padding: .2rem;
    }

    .cartographerContainer {
        padding: 0px;
        padding-top: var(--defaultPadding);
    }

}

@media(max-width: 425px){

    :root{
        --tileWidth: 2.5rem; 
        --defaultPadding: .5rem; 
        --largeFontSize: .9rem;
        --defaultFontSize: .8rem; 
        --defaultMargin: 1rem; 
    }

    .blankTile, .blankExampleTile{
        border-width: 1px; 
    }

    .rotateButtonContainer {
        left: 1.5rem;
    }

    .flipButtonPositioner .rotateButtonContainer {
        left: 5rem;
    }

    .startButton{
        margin: var(--defaultMargin) 0; 
    }

    .rotateButtonContainer, .flipButtonPositioner .rotateButtonContainer{
        height: 2rem;
        width: 2rem; 
    }

    .shapeBox, .contractsContainer{
        margin: 0px; 
    }
}