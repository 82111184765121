:root{
    --gridGap: 15px; 
    --navbarPadding: 20px; 
    --clrBackground: #ffffff;
    --clrPrimary: #0097e6;
    --clrSecondary: #f90909;
    --clrTertiary: #008481;
    --chrimbus-green:#075600;
    --chrimbus-red: #aa0000;
    --chrimbus-red-dark: #8d0202;
    --chrimbus-gold: #f0db4d;
    --navbarFontSize: 1.3rem; 
    --homeBodyMargin: 8rem;
    --defaultPadding: 1.5rem; 
    --defaultColumns: auto auto auto;
    --defaultGridGap: 2rem; 
    --defaultTextColor:#393A3D;
    --tileWidth: 5rem;
    --exampleTileWidth: calc(var(--tileWidth) * .3); 
    --defaultBorderRadius: 1rem; 
    --defaultMargin: 2rem; 
    --defaultFontSize: 1.05rem; 
    --largeFontSize: 1.4rem; 
    --smallFontSize: .8rem; 
    overflow-x: hidden;
}

.mobileSubDropdownText {
    padding-left: 20px;
    display: flex;
    align-items: center;
}

body {
    overflow-x: hidden;
}

.loginContainerTextRow.chrimbus {
    color: white;
}

.spookySignIn{
    color: #ff7002 !important;
    border-color: #ff7002 !important;
}

.chrimbusSignIn{
    color: var(--chrimbus-gold) !important;
    border-color: var(--chrimbus-gold) !important;
}

.width-150 {
    max-width: 150px;
}

.flex {
    display: flex !important;
}

.flex.row {
    flex-direction: row;
}

.flex.col {
    flex-direction: column;
}

.flex.max {
    flex: 1 1 auto;
}

.flex.centered {
    align-items: center;
    justify-content: center;
}

.flex.spaced {
    gap: var(--defaultPadding);
}

.flex.wrap {
    flex-wrap: wrap;
}

.flexContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: var(--defaultPadding);
}

.clrPrimary {
    color: var(--clrPrimary);
}

.clrSecondary {
    color: var(--clrSecondary);
}

.clrTertiary {
    color: var(--clrTertiary);
}

.MuiDialog-root.centisenpai .MuiBackdrop-root {
    background: none !important;
}

.clrSpring {
    color: #009e73;
}

.clrSummer {
    color: #f0e442;
}

.clrFall {
    color: #d55e00;
}

.clrWinter {
    color: #0072b2
}

.outsideSign {
    position: absolute;
    left: -2rem;
}

.dialogItems {
    display: flex;
    flex-direction: column;
    gap: var(--defaultPadding);
}

.dialogTextContent {
    line-height: 1em;
}

.MuiDialogContent-root {
    font-size: 1em !important;
}

.charityItem, .charityItem a{
    color: var(--defaultTextColor);
}

.MuiBox-root {
    width: 100% !important;
    height: auto !important;
}

.flex {
    display: flex !important;
}

.flex.max {
    flex: 1 1 auto;
}

.MuiDialogActions-root {
    display: flex !important;
    justify-content: space-around !important;
}

.formFields {
    display: flex;
    flex-direction: column;
    gap: var(--defaultPadding);
    gap: 1em;
}

.formLine {
    display: flex;
    flex-direction: row;
    gap: var(--defaultPadding);
}

.MuiDialogContent-root {
    overflow-x: hidden !important;
}

.centerButtons { 
    display: flex !important;
    justify-content: center !important;
}

.solvedBox, .badGuessBox {
    color: white;
    font-weight: bolder;
    font-size: 2em;
    text-align: center;
    padding: var(--defaultPadding);
    min-height: 10em;
    min-width:10em;
    overflow:hidden;
    display:flex;
    justify-content: center;
    align-items: center;
}

.solvedBox {
    background-color: darkgreen;
}

.badGuessBox {
    background-color: #d51a1a;
}

.bold {
    font-weight: bold;
}

.cursedCaptchaTileBoard {
    display: grid;
    gap: var(--defaultPadding);
    grid-template-columns: auto auto auto;
}

.logoList {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.4rem;
    color: #393a3d;
    font-weight: 400;
    font-family: avenir,helvetica,sans-serif;
}

a{
    color: var(--clrBackground); 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

h1{
    font-size: 1.5rem;
    font-weight: 600; 
    margin:0px;
}

.otherNavbarContainer .signInBtnContainer {
    justify-content: end;
    flex: 0 0 auto;
    padding-left: var(--defaultPadding);
}

.navbarContainer{
    text-align: center;
    padding: var(--navbarPadding);
    color: var(--clrBackground);
    background-color: var(--clrPrimary);
    font-size: var(--navbarFontSize);
    display: flex;
    align-items: center;
    gap: var(--defaultPadding);
}

.navbarContainer.spooky {
    background-color: #ae04ff;
    font-weight: bolder;
}

.navbarItems {
    display: flex;
    height: 100%;
    flex: 1 1 auto;
}

.navbarIconContainer{
    text-align: right;
    flex: 0 0 auto;
    display: flex;
}

.navbarTextContainer {
    display: flex;
    gap: var(--defaultPadding);
    text-align: left;
    flex: 1 1 auto;
    justify-content: center;
}

.otherNavbarContainer{
    border-bottom: 1px solid #d4d7dc;
    display: flex;
    gap: var(--defaultPadding);
    align-items: center;
    height: 4rem;
    position: relative;
    padding: 0 var(--defaultPadding);
    justify-content: space-between;
}

.otherNavbarContainer div{
    margin: 0px; 
}

.otherNavbarContainer div a{
    font-size: 19px;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    height: 100%;
    display: flex;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    color: var(--defaultTextColor);
}

.otherNavbarContainer .desktopDropdownLink:after{
    content: "";
    width: 0%;
    border-bottom: 4px solid var(--clrSecondary);
    position: absolute;
    bottom: 0px;
    left: 50%;
    transition: all .3s;
}

.otherNavbarContainer.spooky div a:after {
    border-color: #ae04ff;
}

.otherNavbarContainer .desktopDropdownLink:hover:after{
    width: 100%;
    left: 0;
}

.otherNavbarContainer.chrimbus {
    background-color: var(--chrimbus-red);
}

.otherNavbarContainer.chrimbus div a:after {
    border-color: var(--chrimbus-gold);
}

.otherNavbarContainer.chrimbus div a {
    color: white;
}

.signInBtn {
    color: #0097E6;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: center;
    display: flex;
    align-items: center;
}

.signInBtn.spooky {
    color: #ff7002;
    border-color: #ff7002;
}

.signInBtn.chrimbus {
    color: var(--chrimbus-gold);
    border-color: var(--chrimbus-gold);
    font-weight: bold;
}

.signInBtn:hover{
    background-color: #0097e652;
}

.desktopDropdownLink {
    text-decoration: none; 
    color: var(--defaultTextColor);
    position: relative;
    justify-content: center;
}

.desktopDropdownItems {
    position: absolute;
    max-height: 0px;
    transition: all .3s;
    overflow: hidden;
    background: #fcfcfc;
    top: 64px;
    width: max-content;
    display:flex;
    flex-direction: column;
    left: 0px;
}

.desktopDropdownItems.chrimbus {
    background-color: var(--chrimbus-red-dark);
}

.desktopDropdownHoverItem {
    padding: calc(.5 * var(--defaultPadding)) var(--defaultPadding);
    text-decoration: none;
    position: relative;
}

.desktopDropdownHoverItem:after {
    position: absolute;
    background-color: var(--clrSecondary);
    /* height: 100%; */
    height: 0px;
    width: 3px;
    transition: all .2s;
    content: '';
    left: 0px;
}

.desktopDropdownHoverItem.chrimbus:after {
    background-color: var(--chrimbus-gold);
}

.desktopDropdownHoverItem:hover:after {
    height: 100%;
}

.desktopDropdownLink:hover .desktopDropdownItems {
    /* border: solid 1px; */
    /* border-top: 0px; */
    /* border-left: 0px; */
    max-height: none;
    width: -webkit-fill-available;
    z-index: 4;
}

.homeBody{
    padding: var(--defaultPadding) var(--homeBodyMargin);
    /* margin: 0px var(--homeBodyMargin); */
    display: flex;
    /* padding: var(--defaultPadding) 0; */
    flex-direction: column;
    gap: var(--defaultPadding);
}

.homeBody.spooky {
    background: black;
    color: white;
    font-weight: bold;
}

.homeBody.spooky h1 {
    color: #ff7002;
}

.homeBody.chrimbus {
    background: var(--chrimbus-green);
    font-weight: bold;
}

.prettyGrid{
    margin: 60px 0px; 
    display: flex;
    gap: var(--defaultPadding);
    justify-content: space-between;
}

.prettyGridCaption{
    padding: var(--defaultPadding); 
    padding-left:0px;
    padding-right: 0px;
    color: var(--clrPrimary); 
    letter-spacing: 2.5px; 
    text-transform: uppercase; 
}

.prettyGridCaption.chrimbus {
    color: var(--chrimbus-gold);
}

.mobileSignInContainer {
    display: flex;
    align-items: center;
}

.prettyImage{
    object-fit: cover; 
    width:100%; 
    max-height: 100vh; 
    vertical-align: middle; 
}

.loginBox{
    padding: var(--defaultPadding);
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: #c1c5c8;
    display: flex;
    flex-wrap: wrap;
    gap: var(--defaultPadding);
}

.prettyGridPunchline.chrimbus {
    color: white;
}

.disclaimer.chrimbus {
    color: #faefa1;
}

.loginBox.chrimbus {
    background-color: #176311;
    border-width: 0px;
    color: white;
    font-weight: bold;
}

.chrimbus-sign-in {
    background-color: var(--chrimbus-red) !important;
}

.centisenpai .MuiPaper-root {
    overflow: visible;
    background: var(--centisenpaiBodyColor);
}

.centisenpai .MuiPaper-root .MuiDialogContent-root {
    overflow-x: visible !important;
    padding: 0px;
}

.centisenpai .formFields .formFields {
    position: absolute;
    background-color:red;
}

.centisenpai .MuiPaper-root .formLine {
    width: 100%;
}

p{
    margin: var(--defaultPadding) 0;
}

.logoGrid{
    display: flex;
    gap: var(--defaultPadding);
    align-items: center;
    flex-wrap: wrap;
}

.logoInit{
    padding-top: var(--defaultPadding); 
    font-weight: bold; 
    font-size: 2rem; 
    flex: 0 0 auto;
}

.logoList div{
    padding: 0px;
    display: flex;
    font-size: .8em;
    align-items: center;
    gap: 0.4em;
}

.loginContainerTextRow {
    font-family: "AvenirNext", Avenir, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #393A3D;
    line-height: 1.1;
    font-size: 1.5rem;
    font-weight: bold;
}

.aboutKat {
    flex: 1 1 auto;
}

.signInForms {
    display: flex;
    flex-direction: column;
    gap: var(--defaultPadding);
    flex: 1 1 auto;
}

.signInForms input{
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: #c1c5c8;
    width: 100%;
    padding: 5px;
    padding-top: 8px;
    margin: 0;
    box-sizing: border-box;
    font-size: .8em; 
}

.fullRow {
    display: flex;
    align-items: center;
    gap: var(--defaultPadding);
}

.signInBtnContainer{
    padding: 0px;
    border-style: hidden;
    flex: 1 1 auto;
    display: flex;
}

.signInBtn2{
    font-size: 1.3rem; 
    vertical-align: middle; 
    text-align: center; 
    color: var(--clrBackground); 
    cursor: pointer;
    background-color: var(--clrPrimary); 
    border-color: var(--clrPrimary); 
    display: flex;/*
    width: 100%;
    height: 100%;*/
    border-style: solid;
    border-width: 0px;
    border-radius: 5px; 
    margin: 0px;

    vertical-align: middle;
    text-align: center;
    padding: 5px 10px;
}

.disclaimer {
    font-size: .7rem;
    font-style: italic;
    color: #757575;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    text-align: left;
    font-family: "AvenirNext", Avenir, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    line-height: 1.5;
    border-style: hidden;
}

.checkbox {
    height: 20px;
    width: 20px; 
    margin: 0px;
}

.signInForms .checkbox{
    width: 20px; 
}

.rememberMe {
    font-size: 1rem;
    font-weight: normal;
    font-family: "AvenirNext", Avenir, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: left;
}

.disclaimer a, .fullRow a{
    cursor: pointer;
    color: var(--clrPrimary); 
}

.spaceMe{
    padding: 0px 5px; 
}

.imageGrid{
    display: flex;
    flex-direction: row;
    gap: var(--defaultPadding);
    flex-wrap: wrap;
}

.tile{
    text-align: center;
    border-radius: 5px;
    flex: 1 1 auto;
}

.tileTitle{
    padding: var(--defaultPadding) 0;
    color: var(--clrBackground); 
    font-size: 1.4rem; 
    font-weight: 500; 
    margin: 0px; 
}

.startForFree {
    font-size: .58em;
    color: var(--clrPrimary);
    margin-bottom: var(--defaultPadding);
}

.startForFree.chrimbus {
    color: white;
}

.startForFree a {
    text-decoration: underline;
    cursor: pointer;
}

.tileImg {
    display: block;
    width: 60%;
    height: auto;
    padding-left: 20%;
    padding-right: 20%;
    border-bottom: solid;
    border-bottom-width: 1px;
    border-radius: 5px;
    border-bottom-color: #c1c5c8;
}

.ratingContainer {
    display: flex;
    gap: var(--defaultPadding);
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.footer {
    min-height: 380px;
    padding: 48px 0;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient( 265deg,#00c1bf,#055393 117%);
    font-size: 1.4rem;
    color: #393a3d;
    font-weight: 400;
    font-family: avenir,helvetica,sans-serif;
}

.footer.spooky {
    /* background: linear-gradient( 265deg, #ff7002, #ae04ff 117%); */
    background: linear-gradient( 265deg, #0ef705, #ae04ff 65%);

    /* #0ef705 */
}

.footer.chrimbus {
    background: linear-gradient( 265deg, #aa0000, #dd0000 65% );
}

.snowflake.mobile {
    color: azure;
    font-size: 100px;
    opacity: 0.8;
    animation-name: fall;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    position: fixed;
    top: -100px;
    z-index: 3;
  }

  .snowflake.fixed {
    font-size: 2.7rem;
    color: azure;
  }

  .snowflake.blue {
    color: #40b5ff;
  }
  
  @keyframes fall{
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh) rotate(360deg);
      opacity: 0;
    }
  }  

.ratingContent {
    line-height: 1.2;
    font-weight: 400;
    font-size: 2.4rem;
    color: var(--clrBackground); 
}

.ratingMessage{
    margin: var(--defaultPadding) 0px; 
    font-size: .7rem; 
    color: var(--clrBackground); 
    text-align: center;
    font-weight: 400; 
    font-family: avenir, Arial, Helvetica, sans-serif, sans-serif; 
}

.star {
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .9em;
    margin-right: .9em;
    margin-bottom: 1.2em;
    border-right: .3em solid transparent;
    border-bottom: .7em solid #FC0;
    border-left: .3em solid transparent;
    font-size: 24px;
    font-size: 20px;
}

.star:before, .star:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: .6em;
    left: -1em;
    border-right: 1em solid transparent;
    border-bottom: .7em solid #FC0;
    border-left: 1em solid transparent;
    transform: rotate(
                    -35deg
                    );
}

.star:after {
    transform: rotate(35deg);
}

.bad, .bad:before, .bad:after
{
    border-bottom: .7em solid grey; 
}

.mobileNavbarContainer{
    display:none;
    position: relative;
}

.mobileNavbarContainer.chrimbus {
    background-color: var(--chrimbus-red);
    border-bottom: none !important;
}


.hamburger {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 10% 10% 10%;
    grid-row-gap: 8%;
    -webkit-justify-content: normal;
    justify-content: normal;
    cursor: pointer;
    height: 100%;
    max-height: 2rem;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    grid-template-rows: 20% 20% 20%;
    align-content: space-evenly;
    width: 2rem;
    z-index: 2;
}

.left, .mid, .right {
    background-color: black;
    font-size: 12px;
}

.hamburger.chrimbus .left, .hamburger.chrimbus .mid, .hamburger.chrimbus .right {
    background-color: var(--chrimbus-gold);
}

.left, .right {
    -webkit-transform: rotate( 0deg );
    transform: rotate( 0deg );
    transition: none;
    transition: all .18s; 
}

.hamburger.toggled .left {
    -webkit-transform: translateY(180%) rotate( 45deg );
    transform: translateY(180%) rotate( 45deg );
}

.hamburger.toggled .right {
    -webkit-transform: translateX(0) rotate( -45deg );
    transform: translateX(0) rotate( -45deg );
}

.hamburger.toggled .left, .hamburger.toggled.right {
    transition: -webkit-transform .2s ease-in-out 25ms;
    transition: transform .2s ease-in-out 25ms;
    transition: transform .2s ease-in-out 25ms,-webkit-transform .2s ease-in-out 25ms;
}

.hamburger.toggled .mid{
    display: none; 
    opacity: 0; 
    transition: opacity .3s, visibility 0s; 
}

.mobileDropdown{
    position: absolute; 
    max-height: 0px; 
    display: block;
    top:66px;
}

.mobileDropdown .desktopDropdownLink{
    height:0px;
    max-height:0px;
    display:block;
}

.mobileDropdown *{
    max-height: 0px;
    height:0px;
    visibility: hidden; 
    transition: height .3s, max-height .3s;
}

.mobileDropdown.chrimbus .desktopDropdownLink{
    background: var(--chrimbus-red);
    color: white;
    font-weight: bold;
}

.mobileDropdown.show{
    position: absolute;
    width: 100%;
    left: 0px;
    height:100%;
}

.mobileDropdown.show.spooky * {
    background-color: #320447;
    color: white;
    font-weight: bold;
}

.mobileDropdown.show * {
    visibility: visible; 
    max-height: 200px;
    height: auto; 
    z-index: 1; 
    /* background-color: var(--clrBackground);  */
    background-color: #fafafa;
    width:100%; 
}

.mobileDropdown.show .desktopDropdownLink{
    width: 75%;
    width: 100vw;
    display: block;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    position: relative;
    line-height: 20px;
    /* padding: 20px; */
    display: flex;
    padding: 10px 0px;
}

.mobileDropdown.show .desktopDropdownLink .hamburger {
    /* background-color: black; */
    height: -webkit-fill-available;
}

.mobileDropdown.show .hamburgerContainer {
    padding-right: 20px;
    width: 2rem;
    height: 2rem;
}

.desktopDropdownLink .hamburger {
    background: var(--clrBackground);
}

.mobileDropdown.show .left, .mobileDropdown.show .mid, .mobileDropdown.show .right {
    background: black;
}

.desktopDropdownLink.singleton {
    padding-left: 20px !important;
    justify-content: flex-start;
}

.mobileSubDropdown {
    /* height: 0px; */
    max-height: 0px !important;
    transition: all .3s;
    display: flex;
    flex-direction: column;
    transition: all .3s;
    overflow: hidden;
}

.mobileSubDropdownItem {
    color: var(--defaultTextColor);
    text-decoration: none;
    padding: .5rem 40px;
    font-size: 16px;
}

.mobileSubDropdown.show {
    height: -webkit-fill-available;
    max-height: none !important;
}

.parsleyPic img{
    width:auto;
}

.cursedCaptcha{display:none;}

.cursedCaptcha.toggled {
    display: block; 
    background-color: #0000008a;
    top: 0px;
    left: 0px;
    z-index: 2;
    height: 100vh;
    width:100vw; 
    position: fixed; 
}

.cursedCaptcha.toggled .cursedCaptchaTileBoard {
    width: 50%;
    margin: 0 auto;
    margin-top: calc(.4*var(--defaultPadding)); 
    margin-bottom: calc(.4*var(--defaultPadding)); 
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-template-rows: 30% 30% 30%;
    grid-column-gap: 2%; 
    grid-row-gap: calc(.6*var(--defaultPadding)); 
}

.cursedCaptcha.toggled .cursedCaptchaTitle{
    display: block; 
    text-align: center; 
    width: 50%; 
    margin: 0 auto; 
    margin-top: 5%; 
    color: var(--clrBackground); 
    background-color: var(--clrPrimary); 
    padding: calc(1* var(--defaultPadding)) 0px;
    position: relative; 
}

.fullRow.rememberMe div {
    cursor: pointer;
    text-decoration: underline;
}

.cursedTile{
    height: 150px;
    width: 150px;
    position: relative;
    cursor: pointer;
}

.cursedTile:after{
    visibility: hidden; 
    transition: all .3s;
    opacity: 0; 
}

.cursedTile.clicked:after{
    visibility: visible;
    opacity: 1;
    content: ''; 
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    position: absolute;
    border-style: solid;
    border-color: limegreen;
    border-width: 4px;
    left: 0px;
}

.cursedTile.clicked:before{
    opacity: .35;
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: black;
}

.cursedTile img{
    height: 100%; 
    width: 100%; 
}

.cursedCaptcha.toggled .cursedButton {
    display: block;
    text-align: center;
    width: 50%;
    margin: 0 auto;
    color: var(--clrBackground);
    background-color: var(--clrPrimary);
    border-radius: 5px;
    padding: calc(1* var(--defaultPadding)) 0px;
}

.cursedCaptcha.toggled.solved{
    display: none; 
}

.bullet {
    background-color: rgb(234,133,21);
    width: 1rem; 
    height: 1rem; 
    border-radius: .5rem; 
    margin-left: .5rem; 
    margin-right: .5rem; 
}

.housewarmingGrid{ 
    margin: 2rem 0; 
    padding: 2rem; 
    height: 35rem; 
    display: grid; 
    grid-template-columns: 50% 50%;
    margin-bottom: 30rem; 
}

.housewarmingGridTile{
    display:inline-block; 
    height: 30rem;
    overflow: hidden; 
    position: relative; 
    cursor: pointer; 
}

.housewarmingGridTileChild {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;

}

.housewarmingGridTile.long {

}

.housewarmingGridTile.down{
    margin-bottom: 0rem; 
}

.housewarmingGridTileTitle{
    margin-left: 1.5rem;
    color: #ffffff;
    font-size: 2rem; 
    font-weight: 500; 
}

.housewarmingGridTile:hover .housewarmingGridTileChild,
.housewarmingGridTile:focus .housewarmingGridTileChild {
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    max-height: 100%;
    max-width: 100%;
}

.housewarmingGridTile:hover .housewarmingGridTileChild:before, 
.housewarmingGridTile:focus .housewarmingGridTileChild:before{
    display:block; 
}

.housewarmingGridTileChild:before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(52,73,94,0.75);
}

.housewarmingGridTileContent {
    position: absolute; 
    top: 40px;
    z-index: 2;
}

.housewarmingGridTile:hover .housewarmingGridTileChild.housewarmingGridTileContent 
.housewarmingGridTile:focus .housewarmingGridTileContent {
    color: red;
    
}

.lineupContainer {
    border-radius: 1.5rem;
}

.lineupContentBox {
    width: 45%;
    margin-left: 35%; 
    margin-right: 20%; 
    color: rgb(234,133,21);
    padding: 2rem 0rem;
}

.lineupBoxTitle{
    font-size: 3.5rem; 
    font-weight: 600; 
}

.lineupBox {
    background: hsl(0 0% 0%/.9) 100%;
    border-radius: 1rem;
    color: #ffffff;
    font-size: 2rem;
    padding: 2rem;
    margin-top: 1rem;
}

.lineupBox div{
    display: inline-block; 
}

.mobileNavbarContainer.spooky {
    background-color: black;
    color: white;
    border-width: 0px;
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: center; */
    /* align-items: center; */
}

.navbarContainer.chrimbus {
    background-color: var(--chrimbus-green);
    /* color: gold; */
}

.mobileNavbarContainer.spooky .left, .mobileNavbarContainer.spooky .right, .mobileNavbarContainer.spooky .mid {
    background-color: white;
}

@media(max-width: 768px){

    .navbarTextContainer {
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
    }

    .navbarContainer {
        font-size: 1rem;
    }

    .MuiDialogTitle-root, .MuiDialogContent-root {
        padding-left: var(--defaultPadding) !important;
        padding-right: var(--defaultPadding) !important;
    }

    .MuiDialogContent-root {
        padding-bottom: var(--defaultPadding) !important;
    }

    .cursedTile {
        width: 100px;
        height: 100px;
    }

    .otherNavbarContainer{
        display:none; 
    }

    .homeBody {
        margin: 0;
    }

    .prettyGrid{
        margin: 0px;
        display: flex;
        flex-direction: column;
        gap: var(--defaultPadding);
    }

    .hamburgerContainer {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .mobileNavbarContainer {
        background-color: var(--clrBackground);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #d4d7dc;
        display: flex;
        flex-direction: row;
        padding: var(--defaultPadding);
        gap: var(--defaultPadding);
    }

    .cursedCaptcha.toggled .cursedCaptchaTileBoard,
    .cursedCaptcha.toggled .cursedCaptchaTitle,
    .cursedCaptcha.toggled .cursedButton
    {
        width:80%; 
    }

    .signInBtn{
        margin: 0px;
        padding: var(--defaultPadding);
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .lineupContentBox{
        width: 65%;
        margin-left: 25%; 
        margin-right: 10%; 
    }
    .housewarmingGrid {
        grid-template-columns: auto;
        height: auto;
        margin-bottom: 2rem;
    }
    .ineupBox{
        padding: 1.5rem; 
    }
}

@media(max-width: 425px){

    :root {
        --navbarFontSize: .8rem;
        --navbarPadding: 10px;
        --homeBodyMargin: 1rem;
        --defaultColumns: auto; 
        --defaultGridGap: .5rem; 
        --defaultPadding: .5rem; 
    }

    .snowflake.fixed {
        font-size: 2rem !important;
    }

    .cursedCaptcha.toggled .cursedCaptchaTileBoard,
    .cursedCaptcha.toggled .cursedCaptchaTitle,
    .cursedCaptcha.toggled .cursedButton
    {
        width: 95%;
    }

    .mobileNavbarContainer {
    }

    .imageGrid{
        grid-row-gap: calc(2*var(--defaultGridGap)); 
    }

    .signInBtn2{
        padding: var(--defaultPadding) 0px; 
        text-align: center
    }

    .logoInit{
        font-size: 1.5rem; 
    }

    .logoList div{
        font-size: 1em; 
    }

    .loginContainerTextRow{
        font-size: 1.2rem; 
    }

    body{
        font-size: .7rem; 
        max-width: 100%; 
    }

    h1{
        font-size: 1rem;
    }

    .prettyGrid{
        grid-template-columns: auto; 
    }

    .loginBox{
        grid-template-columns: auto; 
    }

    .cursedCaptcha.toggled .cursedCaptchaTileBoard{
        grid-row-gap: 15px;
    }

    .lineupContentBox{
        width: 75%;
        margin-left: 20%;
        margin-right: 5%; 
    }

    .lineupBoxTitle{
        font-size: 2rem; 
    }

    .lineupBox{
        padding: 1rem; 
        font-size: 1.5rem; 
    }

    .housewarmingGridTileContent{
        font-size: 1.5rem; 
    }
}