

.phoneWidget {
    display: block;
    border-bottom: solid;
    border-bottom-width: 1px;
    border-radius: 5px;
    border-bottom-color: #c1c5c8;
    position: relative;
    overflow: hidden;
}

.phone {
    height: 100%;
    width: 60%;
    padding-left: 20%;
    padding-right: 20%;
    background-color: #f4f5f8;
}

.phoneTop{
    background-color: #393a3d;
    height: 3rem;
    border-style: solid;
    border-color: #ffffff;
    border-width: 3px;
    border-bottom-width: 0px;
    border-top-left-radius: 3.5rem;
    border-top-right-radius: 3.5rem;
}

.phoneBody {
    background-color: #ffffff;
    position: relative;
}

.phoneCamera{
    background-color: red;
    display: flex;
    padding-left: auto;
    padding-right: auto;
}

.phoneScreen{
    background-color: yellow;
    height: 75%;
    position: relative;
    display: flex;
}

.phoneScreen::after, .phoneScreen::before{
    display: block;
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    top: 0;
    background: white;
    z-index: 1;
}

.phoneScreen::after{
    right:0px;
}

.phoneScreen::before{
    left:0px;
}

.phoneImg{
    height: 100%;
    min-width: 100%;
    position: relative;
}

.phoneOptions {
    font-size: .8rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: var(--defaultPadding);
    padding: var(--defaultPadding);
}

.phoneOptions .MuiCheckbox-root {
    padding: 0px !important;
}

.phoneOption {
    position: relative;
    transition: all .2s;
    font-size: .7rem;
}

.phoneOptionCheckbox {
    display: flex;
    align-items: center;
}

.optionPrompt {
    display: flex;
    align-items: center;
}

.optionPrompt .MuiSvgIcon-root {
    color: #b5642b;
}

.phoneOption::after{
    position: absolute;
    height: 1.2rem;
    width: 2rem;
    transform: rotate(-45deg);
    top: -.8rem;
    left: 20%;
    content: '';
    cursor: pointer;
    transition: all .3s;
    border-color: var(--clrPrimary);
    border-width: 0px;
    border-left-width: 5px;
    border-bottom-width: 5px;
    border-style: solid;
    opacity: 0;
}

.phoneOption.selected::after{
    opacity: 1;
}

.phoneOption::before{
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 0px;
    left: 20%;
    content: '';
    cursor: pointer;
    border-color: var(--clrPrimary);
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;
    transition: all .3s;
}

.phoneOption:hover::before{
    background-color: #c1c5c8
}

.tlPhoneBtn, .blPhoneBtn {
    position: absolute;
    width: 4px;
    left: -4px;
    background-color: #ffffff;
    height: 15%;
}

.tlPhoneBtn {
    top: 10%;
    cursor: pointer;
}

.tlPhoneBtn:hover {
    background-color: #ffffffcb;
}

.blPhoneBtn {
    top: 30%;
}

.trPhoneBtn {
    right: -4px;
    position: absolute;
    width: 4px;
    background-color: #ffffff;
    top: 10%;
    height: 30%;
}

@media(max-width: 768px) {
    .phoneWidget {
        height: auto;
    }
}

@media(max-width: 425px) {
}