:root {
    --centisenpaiGray: #7f93ab;
    --centisenpaiBodyColor: #293c8b;
    --centisenpaiClawColor: #9dacc1;
    --centisenpaiAntennaColor: #0c1734;
    --centisenpaiChitinColor: #486181;
}

.healthWidgetContainer {
    background: #f7f6d7;
    padding: 5px 10px;
    position: absolute;
    top: -265px;
    width: 400px;
    width: 100%;
    display: flex;
    border: solid #152b07 4px;
    border-radius: 10px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    z-index: 4;
    font-family: 'Pokemon Solid', sans-serif;
    flex-direction: column;
}

.hp {
    color: #eb895a;
}

.healthContainer {
    flex: 1 1 auto;
    border-radius: 7px;
    border: solid #f7f6d7 3px;
}

.health {
    background: #7aeea7;
    height: 100%;
    transition: all .3s;
}

.pokemonNameContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.healthWidget {
    width: 60%;
    height: 20px;
    border-radius: 10px;
    padding: 4px;
    display: flex;
    background: #152b07;
    /* background: #7aeea7; */
}

.centisenpai .MuiPaper-root, .centisenpai .formFields .formFields {
    background: var(--centisenpaiChitinColor);
}

.centisenpai .MuiPaper-root .formLine::after{
    display: block;
    position: absolute;
    content: '';
    height: 4rem;
    width: 4rem;
    background: green;
}

.centisenpaiHead {
    position: absolute;
    height: 10rem;
    width: 100%;
    background-color: var(--centisenpaiBodyColor);
    top: -10rem;
    border-top-right-radius: 10rem;
    border-top-left-radius: 10rem;
    position: relative;
    display: flex;
    justify-content: center;
}

.centisenpaiFaceContainer {
    height: 7rem;
    width: 5rem;
    width: 90px;
    border-radius: 4rem;
    margin-top: 1rem;
    background-color: var(--centisenpaiGray);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.centisenpaiFace {
    height: 5rem;
    width: 5rem;
    background-color: white;
    border-radius: 5rem;
    position: relative;
}

.centisenpaiFaceDot{
    height: 7px;
    width: 7px;
    border-radius: 5px;
    background-color: black;
    top: 9px;
    position: absolute;
}

.centisenpaiFaceDot.left{
    left: 20px;
}

.centisenpaiFaceDot.right{
    right: 20px;
}


.centisenpaiEye {
    height: 1rem;
    width: 1rem;
    border-radius: 1rem;
    background-color: black;
    position: absolute;
    top: 1.5rem;
}

.centisenpaiEye.right {
    right: 1rem;
}

.centisenpaiEye.left {
    left: 1rem;
}

.centisenpaiNose {
    border: solid black 2px;
    height: 1.1rem;
    position: absolute;
    width: 0.75rem;
    top: 2rem;
    left: 2.2rem;
    border-top: none;
    border-right: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 7px;
}

.centisenpaiClaw {
    position: absolute;
    height: 6rem;
    width: 3rem;
    top: 2rem;
    background-color: var(--centisenpaiClawColor);
    overflow: hidden;
   
    /* -webkit-animation-timing-function: cubic-bezier(0.2, 0, 0.09, 1); */
    /* animation-timing-function: cubic-bezier(0.2, 0, 0.09, 1); */
}

.centisenpaiClaw.left{
    left: 30%;
    border-top-left-radius: 78px;
    border-bottom-left-radius: 96px;
    /* -webkit-animation: claw-animation 1s 1s infinite; */
    /* animation: claw-animation 1s 1s infinite; */
}

.centisenpaiClaw.left::after {
    content: '';
    height: 5rem;
    position: absolute;
    left: 26px;
    bottom: 15x;
    bottom: -5px;
    width: 5rem;
    background: var(--centisenpaiBodyColor);
    border-radius: 4rem;
}

.centisenpaiClaw.left::before {
    content: '';
    height: 94px;
    position: absolute;
    left: 13px;
    bottom: 0px;
    width: 5rem;
    background: #8296b1;
    border-radius: 6rem;
    border: solid 1px #4d606f;
}

.centisenpaiClaw.right {
    right: 30%;
    border-top-right-radius: 78px;
    border-bottom-right-radius: 96px;
    /* -webkit-animation: claw-animation-right 1s 1s infinite; */
    /* animation: claw-animation-right 1s 1s infinite; */
}

.centisenpaiClaw.right::after {
    content: '';
    height: 5rem;
    position: absolute;
    right: 26px;
    bottom: 15x;
    bottom: -5px;
    width: 5rem;
    background: var(--centisenpaiBodyColor);
    border-radius: 4rem;
}

.centisenpaiClaw.right::before {
    content: '';
    height: 94px;
    position: absolute;
    right: 13px;
    bottom: 0px;
    width: 5rem;
    background: #8296b1;
    border-radius: 6rem;
    border: solid 1px #4d606f;
}

.centisenpaiMouth.left {
    border: solid black 2px;
    background: white;
    height: 10px;
    position: absolute;
    width: 11px;
    top: 58px;
    left: 24px;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 7px;
    transform: rotate(45deg);
}

.centisenpaiMouth.right {
    border: solid black 2px;
    background: white;
    height: 10px;
    position: absolute;
    width: 11px;
    top: 58px;
    right: 24px;
    border-top: none;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 7px;
    transform: rotate(-45deg);
}

.centisenpaiMouth.top {
    border-top: solid black 2px;
    width: 18px;
    left: 31px;
    top: 56px;
    position: absolute;
}

.centisenpaiMouth.top::after{
    background: black;
    width: 27px;
    top: 3px;
    height: 1px;
    left: -4px;
    content: '';
    position: absolute;
    z-index: 2;
    animation: mouth-chomping 1s 1s infinite;
}

.antennaSegment {
    background-color: var(--centisenpaiAntennaColor);
    width: 2px;
    position: absolute;
    width: 10px;
}

.centisenpai .MuiPaper-root .formLine {
    /* animation: segment-animation 2.5s 1s infinite; */
    /* -webkit-animation: segment-animation 2.5s 1s infinite; */
        -webkit-animation-timing-function: cubic-bezier(0.2, 0, 0.09, 1);
    animation-timing-function: cubic-bezier(0.2, 0, 0.09, 1);
}

@keyframes mouth-chomping{
    50% {
        height: 4px;
        top: 2px;
    }
}

@keyframes segment-animation {
    0% {
        transform: translateX(200px);
    }
    25% {
        transform: translateX(100px);
    }
    50% {
        transform: translateX(-200px);
    }
    75% {
        transform: translateX(-100px);
    }
}

@keyframes claw-animation {
    0% {
      transform: rotate(10deg);
    }
    25% {
      transform: rotate(25deg);
    }
}

@keyframes claw-animation-right {
    0% {
      transform: rotate(-10deg);
    }
    25% {
      transform: rotate(-25deg);
    }
}

@-webkit-keyframes segment-animation {
    0% {
        transform: translateX(200px);
    }
    25% {
        transform: translateX(100px);
    }
    50% {
        transform: translateX(-200px);
    }
    75% {
        transform: translateX(-100px);
    }
}

@-webkit-keyframes claw-animation {
    0% {
      transform: rotate(70deg);
    }
    100% {
      transform: rotate(-650deg);
    }
}